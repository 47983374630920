<template>
  <div>
    <b-card title="Ajout d'information">
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
        >
          <b-form-group label="Type d'information">
            <b-form-select
              v-model="$v.information.type.$model"
              :options="typeOptions"
              :class="{ 'is-invalid': $v.information.type.$error }"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
        >
          <b-form-group label="Etat">
            <b-form-select
              v-model="$v.information.state.$model"
              :options="stateOptions"
              :class="{ 'is-invalid': $v.information.state.$error }"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="12"
          lg="6"
        >
          <b-form-textarea
            v-model="$v.information.message.$model"
            :class="{ 'is-invalid': $v.information.message.$error }"
            rows="3"
          />
          <div
            v-if="$v.information.message.$error"
            class="invalid-feedback"
          >
            <span
              v-if="!$v.information.message.required"
            >{{ $t('errors.required') }}</span>
            <span
              v-if="!$v.information.message.maxLength"
            >Maximun 200 caractères.</span>
          </div>
        </b-col>
        <b-col
          v-if="information.type === 'MESSAGE'"
          sm="12"
          md="12"
          lg="6"
        >
          <b-form-group label="Fichier">
            <b-form-file
              v-model="$v.information.file.$model"
              accept=".jpg, .jpeg, .png"
              placeholder="Choisir une image"
              :class="{ 'is-invalid': $v.information.file.$error }"
              browse-text="Parcourir"
              @change="handleFile($event)"
            />
            <div
              v-if="$v.information.file.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.information.file.required"
              >{{ $t('errors.required') }}</span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-col sm="12">
        <div
          v-if="process"
          class="d-flex justify-content-center mt-1"
        >
          <b-spinner
            variant="primary"
            label="Loading..."
          />
        </div>
        <div class="mt-3">
          <b-button
            variant="secondary"
            class="float-left"
            :disabled="process"
            @click="resetForm"
          >
            {{ $t("form.refresh") }}
          </b-button>
          <b-button
            type="submit"
            variant="outline-primary"
            class="ml-2 float-right"
            :disabled="process"
            @click="tryToSave"
          >
            Ajouter
          </b-button>
        </div>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      information: {
        message: null,
        type: null,
        state: null,
        file: null,
      },
      typeOptions: [
        { value: null, text: 'Sélectionner', disabled: true },
        // { value: 'MESSAGE', text: 'MESSAGE' },
        { value: 'NOTIFICATION', text: 'NOTIFICATION' },
      ],
      stateOptions: [
        { value: null, text: 'Sélectionner', disabled: true },
        { value: true, text: 'Afficher' },
        { value: false, text: 'Désactiver' },
      ],
    }
  },
  validations: {
    information: {
      message: {
        required,
        maxLength: maxLength(200),
      },
      type: {
        required,
      },
      state: {
        required,
      },
      file: {
        // eslint-disable-next-line func-names
        required: requiredIf(function () {
          if (this.information.type === 'MESSAGE') {
            return true
          }
          return false
        }),
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'noteSuccess',
      error: 'noteError',
      process: 'noteProcess',
    }),
  },
  watch: {
    success(val) {
      if (val) {
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Ajout d'information",
            text: 'Ajouté avec succes',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Ajout d'information",
            text: 'Une erreur est survenue',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['note']),
    handleFile(e) {
      [this.information.file] = e.target.files
    },
    resetForm() {
      this.information = {
        message: null,
        type: null,
        state: null,
        file: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    tryToSave() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.note({
        information: this.information.message,
        state: this.information.state,
        type: this.information.type,
        file: this.information.file,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
 .cursor {
   cursor: pointer
 }
@import '@core/scss/vue/libs/quill.scss';
</style>
